import React, {Component} from 'react';
import { Link } from 'react-router-dom';

class index extends Component {
  //构造函数  第一个触发
  constructor(props) {
    super(props);
    this.state = {
      list: [{
        url: require('../../assets/images/banner1.png')
      }, {
        url: require('../../assets/images/banner2.png')
      }, {
        url: require('../../assets/images/banner3.png')
      }],
      toMoveIndex: 0,
      slideWidth: 0,
      slideLeft: 0,
      slide: true,
      Interval: '',
      mouseHover1: false,
      mouseHover2: false,
      mouseHover3: false,
      mouseHover4: false,
      developmentList: [{
        icon: require('../../assets/images/ic_home_bank.png'),
        title: '银行金融解决方案',
        instructions: '满足金融行业对交易时间准确、系统数据超高安全存储的要求，帮助金融客户快速实现网络部署，提升企业竞争力。'
      },{
        icon: require('../../assets/images/ic_home_mall.png'),
        title: '电商平台解决方案',
        instructions: '全渠道电商解决方案，助力企业构建私域流量进入品牌电商新时代。完整开店系统，轻松搭建在线商城，分销裂变提高销量，让人人都是商家分销员。'
      },{
        icon: require('../../assets/images/ic_home_ai.png'),
        title: 'AI应用解决方案',
        instructions: '精耕细作，持续创新，用人工智能产品取代手工输入，精准提供文字识别服务，为用户呈现结构化数据，提升工作效率。'
      }],
      downList: [{
        url: require('../../assets/images/phone1.png')
      }, {
        url: require('../../assets/images/phone2.png')
      }, {
        url: require('../../assets/images/phone3.png')
      }, {
        url: require('../../assets/images/phone4.png')
      }],
      customerList: [{
        url: require('../../assets/images/image_logo_geli.png')
      }, {
        url: require('../../assets/images/image_logo_spd.png')
      }, {
        url: require('../../assets/images/image_logo_lzzl.png')
      }, {
        url: require('../../assets/images/image_dainwang.png')
      }/*, {
        url: require('../assets/images/image_logo_pinjia.jpg')
      },{
        url: require('../assets/images/image_logo_winpay.jpg')
      }, {
        url: require('../assets/images/image_logo_xiaoyuanbao.jpg')
      }, {
        url: require('../assets/images/image_logo_yunqifu.jpg')
      }*/],
    }
  }

  AutoSlide = () => {
    let that = this;
    let index = this.state.toMoveIndex;
    switch (index) {
      case 0:
        that.state.slideLeft = 0;
        that.setState({
          slideLeft: that.state.slideLeft
        });
        break;
      case 1:
        that.state.slideLeft = -100;
        that.setState({
          slideLeft: that.state.slideLeft
        });
        break;
      default:
        that.state.slideLeft = -200;
        that.setState({
          slideLeft: that.state.slideLeft
        });
        break;

    }
  };

  //上一张图片
  icon_back = () => {
    let index = this.state.toMoveIndex;
    if (index !== 0) {
      index--;
      this.setState({
        toMoveIndex: index
      })
    } else {
      index = 2;
      this.setState({
        toMoveIndex: index
      })
    }
    this.AutoSlide();
  };
  //下一张图片
  icon_next = () => {
    let index = this.state.toMoveIndex;
    if (index !== 2) {
      index++;
      this.setState({
        toMoveIndex: index
      })
    } else {
      index = 0;
      this.setState({
        toMoveIndex: index
      })
    }
    this.AutoSlide();
  };

  //自动轮播
  AutoMatical = () => {
    let index = this.state.toMoveIndex;
    if (index < 2) {
      index = index + 1;
    } else {
      index = 0
    }
    this.setState({
      toMoveIndex: index
    });
    this.AutoSlide()
  };


  //数据渲染
  render() {
    const {slide, slideWidth, slideLeft} = this.state;
    return (
      <div className='indexH5 pt-100'>
        <div className='tomove'>
          <ul className={slide ? 'tomove-list transition flex' : 'tomove-list flex'}
              style={{width: slideWidth + '%', left: slideLeft + '%'}}>
            {
              this.state.list.map((val, key) => {
                return (<li className='w03333' key={key}>
                  <img className='w0100' src={val.url} alt=""/>
                </li>)
              })
            }
          </ul>
          <div className='slippage'>
            {
              this.state.list.map((val, key) => {
                return (<div style={{width: 10, height: 10, margin: '0 5px'}} className={this.state.toMoveIndex === key ? 'bg-ea5413 bor-radius-10' : 'bg-999 bor-radius-10'} key={key}></div>)
              })
            }
          </div>
        </div>
        <div className='text-center bg-fff' style={{ padding: '0 0 0.8rem' }}>
          <p className='f-36' style={{color: '#000',padding: '0.8rem 0 0.15rem'}}>了解我们的产品</p>
          <p className='c-999 f-28 pb-50'>- Products -</p>
          <ul className='space-between flex-wrap' style={{ width: '100%',padding: '0 0 0.6rem' }}>
            {
              this.state.developmentList.map((val,key) => {
                return( <li className='developLiH5 text-center' key={key}>
                  <img src={ val.icon } className='mar-auto' style={{ width: '1.2rem',height:'1.2rem' }} alt="" />
                  <p className='f-32 c-333 w0100 f-bold pt-40'>{ val.title }</p>
                  <p className='f-28 c-666 pt-20 text-left'>{ val.instructions }</p>
                </li> )
              })
            }
          </ul>
          <Link to='/Solution'><button className='more1'>更多</button></Link>
        </div>
        <div>
          <img className="w-750" src={ require("../../assets/images/image_home_map_h5.png") } alt=""/>
        </div>
        <div className='text-center bg-fff' style={{ padding: '0 0 0.8rem' }}>
          <p className='f-36' style={{color: '#000',padding: '0.8rem 0 0.15rem'}}>合作伙伴</p>
          <p className='c-999 f-28 pb-50'>- Partner -</p>
          <ul className='space-between mar-auto flex-wrap w0100'>
            {
              this.state.customerList.map((val,key)=>{
                return ( <li key={key} className='pt-40 w050'><img className='mar-auto' style={{ width: '2.56rem' }} src={val.url} alt=""/></li> )
              })
            }
          </ul>
        </div>
        <div className='text-center bg-f5f5f5' style={{ padding: '0 0 0.8rem' }}>
          <p className='f-36' style={{color: '#000',padding: '0.8rem 0 0.15rem'}}>关于我们</p>
          <p className='c-999 f-28 pb-50'>- About Us -</p>
          <div className='pl-30 pr-30 pt-20 f-26 c-666 text-left pb-30'>
            <p style={{ lineHeight: 1.7 }}>成立于2013年，是一家专业从事互联网云计算技术开发与运营服务为一体的高新科技企业。以“互联网+”为理念，利用人工智能、云计算、大数据、物联网等与现代制造业结合，为企业、银行及政府机构提供互联网应用开发、快捷支付、人工智能等综合解决方案。</p>
          </div>
          <Link to='/aboutUs'><button className='more1'>更多</button></Link>
        </div>

        <div className='text-center bg-fff' style={{ padding: '0 0 1rem' }}>
          <p className='f-36' style={{color: '#000',padding: '0.8rem 0 0.15rem'}}>联系我们</p>
          <p className='c-999 f-28 pb-50'>- Contact Us -</p>
          <p className='f-30 c-333 text-left pl-30 pt-20'>厦门崛鼎信息科技有限公司</p>
          <p className='f-26 c-666 text-left pl-30 pt-40'>地址:厦门市思明区新景中心B栋15F</p>
          <p className='f-26 c-666 text-left pl-30 pt-30'>邮编:361000</p>
          <p className='f-30 c-666 text-left pl-30 pt-30'>电话:17338788103 / 0592-5193773</p>
          <p className='f-30 c-666 text-left pl-30 pt-30'>邮箱:customer@jacking.cn</p>
          <div className='pl-30 pt-30'>
            <img style={{ width: '1.2rem' }} src={ require('../../assets/images/wxgzh.jpg') } alt=""/>
            <p className='f-24 pt-20 c-333 text-left'>微信公众号</p>
          </div>
        </div>
      </div>
    )
  }

  //组件生命周期
  componentWillMount() {
    //组件将要挂载  第二个触发
  }

  componentDidMount() {
    //组件挂载完成  第四个触发
    //dom操作和请求数据
    let that = this;
    that.state.slideWidth = that.state.list.length * 100;
    that.setState({
      slideWidth: that.state.slideWidth
    });
    that.state.Interval = setInterval(that.AutoMatical, 3000);
    that.setState({
      Interval: that.state.Interval
    })
  }

  //数据更新生命周期
  shouldComponentUpdate(nextPorps, nextState) {
    //更新前触发，是否更新？返回true或false
    //console.log('是否更新数据');
    return true;
  }

  componentWillUpdate() {
    //组件更新前
    //console.log('更新数据前');
  }

  componentDidUpdate() {
    //组件更新后
    //console.log('更新数据后');
  }

  //组件销毁生命周期
  componentWillUnmount() {
    //组件销毁前
    console.log('组件销毁');
  }

  //在父组件改变props的值，触发函数
  componentWillReceiveProps() {

  }
}

export default index;