import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Footer from './footer';
import Storage from '../../config/Storage';

class solution extends Component {
  //构造函数  第一个触发
  constructor(props) {
    super(props);
    this.state = {
      banner: require('../../assets/images/image_product_banner.jpg'),
      list: [],
      dataIndex: 0,
      //银行金融： 0，电商平台： 1，AI应用： 2，其他：3
      data: [{
        image: require('../../assets/images/image_case_yunqifu.jpg'),
        title: '云企付',
        describe: '企业支付渠道解决方案',
        dataIndex: 0,
        detail: [require('../../assets/images/yqf_01.jpg'),require('../../assets/images/yqf_02.jpg'),require('../../assets/images/yqf_03.jpg')]
      }, {
        image: require('../../assets/images/image_case_qizhiyun.jpg'),
        title: '企助云',
        describe: 'O2O新零售合伙人商城平台',
        dataIndex: 1,
        detail: [require('../../assets/images/qzy_01.jpg'),require('../../assets/images/qzy_02.jpg'),require('../../assets/images/qzy_03.jpg'),require('../../assets/images/qzy_04.jpg'),require('../../assets/images/qzy_05.jpg')]
      }, {
        image: require('../../assets/images/image_case_bank.jpg'),
        title: '银行金融产品',
        describe: '银行金融业务系统解决方案',
        dataIndex: 0,
        detail: [require('../../assets/images/kb_01.jpg'),require('../../assets/images/kb_02.jpg'),require('../../assets/images/kb_03.jpg'),require('../../assets/images/kb_04.jpg'),
          require('../../assets/images/kb_05.jpg'),require('../../assets/images/kb_06.jpg'),require('../../assets/images/kb_07.jpg'),require('../../assets/images/kb_08.jpg')]
      }, {
        image: require('../../assets/images/image_case_xiaoyuanbao.jpg'),
        title: '校园宝',
        describe: 'AI智慧校园云账单系统',
        dataIndex: 2,
        detail: [require('../../assets/images/xyb_01.jpg'),require('../../assets/images/xyb_02.jpg'),require('../../assets/images/xyb_03.jpg'),require('../../assets/images/xyb_04.jpg'),require('../../assets/images/xyb_05.jpg'),require('../../assets/images/xyb_06.jpg'),require('../../assets/images/xyb_07.jpg')]
      }, {
        image: require('../../assets/images/image_case_pinjia.jpg'),
        title: '企助云订货',
        describe: 'B2B供应链商城平台',
        dataIndex: 1,
        detail: [require('../../assets/images/b2b_01.jpg'),require('../../assets/images/b2b_02.jpg'),require('../../assets/images/b2b_03.jpg'),require('../../assets/images/b2b_04.jpg')]
      }, {
        image: require('../../assets/images/image_case_lanren.jpg'),
        title: '懒人阅读',
        describe: '软件开发解决方案',
        dataIndex: 3,
        detail: [require('../../assets/images/lryd_01.jpg'),require('../../assets/images/lryd_02.jpg'),require('../../assets/images/lryd_03.jpg'),require('../../assets/images/lryd_04.jpg')]
      }, {
        image: require('../../assets/images/image_case_kaoqin.jpg'),
        title: '智慧考勤',
        describe: 'AI智慧考勤系统解决方案',
        dataIndex: 2,
        detail: [require('../../assets/images/wkq_01.jpg'),require('../../assets/images/wkq_02.jpg'),require('../../assets/images/wkq_03.jpg'),require('../../assets/images/wkq_04.jpg')]
      }, {
        image: require('../../assets/images/image_case_canteen.jpg'),
        title: '智慧食堂',
        describe: '线上线下一体化综合AI智慧食堂系统',
        dataIndex: 2,
        detail: [require('../../assets/images/zhst_01.jpg'),require('../../assets/images/zhst_02.jpg'),require('../../assets/images/zhst_03.jpg'),require('../../assets/images/zhst_04.jpg'),require('../../assets/images/zhst_05.jpg'),require('../../assets/images/zhst_06.jpg'),require('../../assets/images/zhst_07.jpg')]
      }, {
        image: require('../../assets/images/image_case_rpa.jpg'),
        title: 'RPA自动化流程系统',
        describe: '为企业解决业务流程自动化难题',
        dataIndex: 2,
        detail: [require('../../assets/images/rpa_01.jpg'),require('../../assets/images/rpa_02.png'),require('../../assets/images/rpa_03.png'),require('../../assets/images/rpa_04.png')]
      }, {
        image: require('../../assets/images/image_case_ocr.jpg'),
        title: 'OCR票据识别系统',
        describe: '票据影像智能识别处理系统',
        dataIndex: 2,
        detail: [require('../../assets/images/ocr_01.jpg'),require('../../assets/images/ocr_02.jpg')]
      }, {
        image: require('../../assets/images/ERP.png'),
        title: 'ERP Cloud',
        describe: '企助云智能ERP解决方案',
        dataIndex: 2,
        detail: [require('../../assets/images/erp_01.jpg'),require('../../assets/images/erp_02.jpg'),require('../../assets/images/erp_03.jpg'),require('../../assets/images/erp_04.jpg')]
      }]
    }
  }

  getData = (index) => {
    let that = this;
    that.state.list = [];
    that.state.list = that.state.data.filter(item => item.dataIndex === index);
    that.setState({
      list: that.state.list,
      dataIndex: index
    })
  };

  linkDetail = (data) => {
    if(data.detail.length){
      Storage.sessionStorage.set('detail',data)
    }else {
      alert("内容更新中，敬请期待！")
    }
  };

  //数据渲染
  render() {
    return (
            <div className='Solution'>
              <div className='w0100'>
                <img src={this.state.banner} className='w0100' alt=""/>
              </div>
              <div className="bg-fff" style={{ marginBottom: "40px" }}>
                <div className="w-1200 mar-auto">
                  <div className='c-888 f-16 space-between hl-70 mar-auto' style={{ width: 360 }}>
                    <div className='cursor' onClick={this.getData.bind(this,0)}>
                      <span style={{ height: 68 }} className={this.state.dataIndex === 0 ? 'c-ea5413 bor-b-2-EA5413' : 'c-333'}>银行金融</span>
                    </div>
                    <div className='cursor' onClick={this.getData.bind(this,1)}>
                      <span style={{ height: 68 }} className={this.state.dataIndex === 1 ? 'c-ea5413 bor-b-2-EA5413' : 'c-333'}>电商平台</span>
                    </div>
                    <div className='cursor' onClick={this.getData.bind(this,2)}>
                      <span style={{ height: 68 }} className={this.state.dataIndex === 2 ? 'c-ea5413 bor-b-2-EA5413' : 'c-333'}>AI应用</span>
                    </div>
                    <div className='cursor' onClick={this.getData.bind(this,3)}>
                      <span style={{ height: 68 }} className={this.state.dataIndex === 3 ? 'c-ea5413 bor-b-2-EA5413' : 'c-333'}>其他</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className='mar-auto bg-f8f8f8 pb-100' style={{width: 1200}}>
                <ul className='flex-start flex-wrap SUL'>
                  {
                    this.state.list.map((val,key) => {
                      if(val.detail.length){
                        return ( <Link to='/solutionDetail' key={key}><li className='mart-30' onClick={this.linkDetail.bind(this,val)} style={{ width: 380 }}>
                          <div style={{ width: 380,height: 280,overflow: 'hidden' }}><img src={ val.image } style={{ width: 380 }} alt=""/></div>
                          <div className='bg-fff text-center' style={{ width: 380 }}>
                            <p className='c-333 f-16 pt-20'>{ val.title }</p>
                            <p className='c-888 f-14 pt-10 pb-20'>{ val.describe }</p>
                          </div>
                        </li></Link> )
                      }else {
                        return (<li key={key} className='mart-30' onClick={this.linkDetail.bind(this,val)} style={{ width: 380 }}>
                          <div style={{ width: 380,height: 280,overflow: 'hidden' }}><img src={ val.image } style={{ width: 380 }} alt=""/></div>
                          <div className='bg-fff text-center' style={{ width: 380 }}>
                            <p className='c-333 f-16 pt-20'>{ val.title }</p>
                            <p className='c-888 f-14 pt-10 pb-20'>{ val.describe }</p>
                          </div>
                        </li>)
                      }
                    })
                  }
                </ul>
              </div>
              <Footer />
            </div>
    )

  }

  //组件生命周期
  componentWillMount() {
    //组件将要挂载  第二个触发
  }

  componentDidMount() {
    //组件挂载完成  第四个触发
    //dom操作和请求数据
    let that = this;
    let dataIndex = parseInt(this.props.location.search.replace("?index=",""));
    that.setState({ dataIndex });
    setTimeout(() => {
      that.getData(that.state.dataIndex)
    },200);
  }

  //数据更新生命周期
  shouldComponentUpdate(nextPorps, nextState) {
    //更新前触发，是否更新？返回true或false
    //console.log('是否更新数据');
    return true;
  }

  componentWillUpdate() {
    //组件更新前
    //console.log('更新数据前');
  }

  componentDidUpdate() {
    //组件更新后
    //console.log('更新数据后');
  }

  //组件销毁生命周期
  componentWillUnmount() {
    //组件销毁前
    console.log('组件销毁');
  }

  //在父组件改变props的值，触发函数
  componentWillReceiveProps() {

  }
}

export default solution;