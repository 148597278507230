import React, {Component} from 'react';

class aboutUs extends Component {
  //构造函数  第一个触发
  constructor(props) {
    super(props);
    this.state = {
      banner: require('../../assets/images/image_about_banner.png'),
      toMoveIndex: 0,
      list: [{
        url: require('../../assets/images/develop1.png')
      }, {
        url: require('../../assets/images/develop2.png')
      }, {
        url: require('../../assets/images/develop3.png')
      }, {
        url: require('../../assets/images/develop4.png')
      }],
      rqList: [{
        url: require('../../assets/images/rz0.png')
      },{
        url: require('../../assets/images/rz1.png')
      },{
        url: require('../../assets/images/rz2.png')
      },{
        url: require('../../assets/images/rz3.png')
      },{
        url: require('../../assets/images/rz4.png')
      }],
      bt_left: require('../../assets/images/bt_back.png'),
      bt_right: require('../../assets/images/bt_next.png'),
      slideLeft: 0,
      slideLeft2: 0,
    }
  }

  slide = (e,key) => {
    let slideLeft = this.state.slideLeft;
    if(e===1){
      if(key!==4){
        slideLeft = slideLeft - 100;
      }else {
        alert('没有上一张了')
      }
    }else {
      if(key!==0){
        slideLeft = slideLeft + 100;
      }else {
        alert('没有下一张了')
      }
    }

    this.setState({
      slideLeft
    })
  };

  AutoSlide = () => {
    let that = this;
    let index = this.state.toMoveIndex;
    switch (index) {
      case 0:
        that.state.slideLeft2 = 0;
        that.setState({
          slideLeft2: that.state.slideLeft2
        });
        break;
      case 1:
        that.state.slideLeft2 = -100;
        that.setState({
          slideLeft2: that.state.slideLeft2
        });
        break;
      case 2:
        that.state.slideLeft2 = -200;
        that.setState({
          slideLeft2: that.state.slideLeft2
        });
        break;
      default:
        that.state.slideLeft2 = -300;
        that.setState({
          slideLeft2: that.state.slideLeft2
        });
        break;

    }
  };
  //自动轮播
  AutoMatical = () => {
    let index = this.state.toMoveIndex;
    if (index < 3) {
      index = index + 1;
    } else {
      index = 0
    }
    this.setState({
      toMoveIndex: index
    });
    this.AutoSlide()
  };

  //数据渲染
  render() {
    const {slide, slideWidth, slideLeft2} = this.state;
    return (
      <div className='aboutUsH5'>
        <div className='pt-100'>
          <img className='w0100' src={ this.state.banner } alt=""/>
        </div>
        <div className='text-center bg-fff' style={{ padding: '0 0 0.8rem' }}>
          <p className='f-36' style={{color: '#000',padding: '0.8rem 0 0.15rem'}}>公司简介</p>
          <p className='c-999 f-28 pb-50'>- About Us -</p>
          <div className='f-28 c-666 text-left pb-50' style={{ lineHeight: 1.7,margin: '0 0.3rem',borderBottom: '0.01rem dashed #d3d3d3' }}>
            <p>厦门崛鼎信息科技有限公司（简称：崛鼎科技）成立于2013年，是一家专业从事互联网云计算技术开发与运营服务为一体的高新科技企业。以“互联网+”为理念，利用人工智能、云计算、大数据、物联网等与现代制造业结合，为企业、银行及政府提供互联网应用开发、快捷支付、人工智能等综合解决方案。</p>
            <div className='f-28 c-666 text-left pt-50' style={{ lineHeight: 1.7 }}>
              <p>无论企业是否具有互联网运营经验，崛鼎都将助力他们在新商业浪潮中乘风破浪驶向更宽广的未来。</p>
            </div>
          </div>
          <div className='text-left ' style={{ margin: '0 0.3rem',borderBottom: '0.01rem dashed #d3d3d3' }}>
            <p className='f-32 c-333 f-bold pt-40'>一流的团队</p>
            <p className='f-28 c-666 pt-30 pb-50' style={{ lineHeight: 1.6 }}>创始团队在微软、阿里等一线互联网公司从业多年，视野高、执行力强、快速响应。</p>
          </div>
          <div className='text-left ' style={{ margin: '0 0.3rem' }}>
            <p className='f-32 c-333 f-bold pt-40'>专业的技术</p>
            <p className='f-28 c-666 pt-30 pb-50' style={{ lineHeight: 1.6 }}>支持过亿PV/UV的互联网架构设计、超大型系统的设计实现及Owner经验、可运维的平台级技术架构、一流的视觉及用户体验设计。</p>
          </div>
          <div>
            <img className="w-750" src={ require("../../assets/images/image_about_target_h5.png") } alt=""/>
          </div>
          <div className='text-center bg-fff' style={{ padding: '0 0 0.8rem' }}>
            <p className='f-36' style={{color: '#000',padding: '0.8rem 0 0.15rem'}}>荣誉及专利</p>
            <p className='c-999 f-28 pb-50'>- Honors & Patents -</p>
            <div className="w-690 mar-auto space-between">
              <div className="Honors">
                <img style={{ width: "3.3rem" }} src={require("../../assets/images/image_about_gaoxin_h5.png")} alt=""/>
                <p className="hl-80 text-center f-26 c-333">高新技术企业证书</p>
              </div>
              <div className="Honors">
                <img style={{ width: "3.3rem" }} src={require("../../assets/images/image_about_software_h5.png")} alt=""/>
                <p className="hl-80 text-center f-26 c-333">软件企业证书</p>
              </div>
            </div>
          </div>
          <div className='tomove'>
            <ul className={slide ? 'tomove-list transition flex' : 'tomove-list flex'}
                style={{width: slideWidth + '%', left: slideLeft2 + '%'}}>
              {
                this.state.list.map((val, key) => {
                  return (<li className='w03333' key={key}>
                    <img className='w0100' src={val.url} alt=""/>
                  </li>)
                })
              }
            </ul>
            <div className='slippage'>
              {
                this.state.list.map((val, key) => {
                  return (<div style={{width: 10, height: 10, margin: '0 5px'}} className={this.state.toMoveIndex === key ? 'bg-ea5413 bor-radius-10' : 'bg-999 bor-radius-10'} key={key}></div>)
                })
              }
            </div>
          </div>
          <div className='text-center bg-fff overFlow' style={{ padding: '0 0 0.8rem' }}>
            <p className='f-36' style={{color: '#000',padding: '0.8rem 0 0.15rem'}}>软件著作权</p>
            <p className='c-999 f-28 pb-50'>- Software Copyright -</p>
            <p className="pb-50 f-28 c-666 pl-30 pr-30 text-left" style={{ lineHeight: 1.8 }}>我们的软件均是团队自主开发，并具备计算机软件著作权登记证书。</p>
            <ul className='pos-relative flex overFlow' style={{ width: '500%',left: this.state.slideLeft + '%' }}>
              {
                this.state.rqList.map((val,key)=>{
                  return (
                          <li key={key} className='pos-relative' style={{ width: "20%" }}>
                            <div className='left' onClick={this.slide.bind(this,1,key)}><img src={this.state.bt_left} alt=""/></div>
                            <img style={{ width: '4.5rem',margin: '0 auto' }} src={ val.url } alt=""/>
                            <div className='right' onClick={this.slide.bind(this,2,key)}><img src={this.state.bt_right} alt=""/></div>
                          </li>
                  )
                })
              }
            </ul>
          </div>
      </div>
      </div>
    )
  }

  //组件生命周期
  componentWillMount() {
    //组件将要挂载  第二个触发
  }

  componentDidMount() {
    //组件挂载完成  第四个触发
    //dom操作和请求数据
    let that = this;
    that.state.slideWidth = that.state.list.length * 100;
    that.setState({
      slideWidth: that.state.slideWidth
    });
    that.state.Interval = setInterval(that.AutoMatical, 3000);
  }

  //数据更新生命周期
  shouldComponentUpdate(nextPorps, nextState) {
    //更新前触发，是否更新？返回true或false
    //console.log('是否更新数据');
    return true;
  }

  componentWillUpdate() {
    //组件更新前
    //console.log('更新数据前');
  }

  componentDidUpdate() {
    //组件更新后
    //console.log('更新数据后');
  }

  //组件销毁生命周期
  componentWillUnmount() {
    //组件销毁前
    console.log('组件销毁');
  }

  //在父组件改变props的值，触发函数
  componentWillReceiveProps() {

  }
}

export default aboutUs;