import React, {Component} from 'react';
import Storage from '../../config/Storage';
import "../../assets/css/footer.css"

class footer extends Component {
  //构造函数  第一个触发
  constructor(props) {
    super(props);
    this.state = {}
  }

  //数据渲染
  render() {
    return (
      <div className='footer'>
        <div style={{ backgroundColor: '#1c1c1c',padding: '70px 0' }}>
          <div className='mar-auto space-between c-fff' style={{ width: 1200 }}>
            <div className='text-left' style={{ width: 360 }}>
              <div className='pb-20' style={{ borderBottom: '1px solid #999' }}>
                <p className='f-24'>让我们更近一些，您可以</p>
                <p className='f-14 pt-10'>马上拨打企业专线 客服顾问正在在线等您</p>
              </div>
              <div className='pt-20 pb-50 f-24' style={{ color: '#EA5413' }}>17338788103 / 0592-5193773</div>
              <div className='pb-20' style={{ borderBottom: '1px solid #999' }}>
                <p className='f-24'>您还可以</p>
                <p className='f-14 pt-10'>通过发送邮件 咨询您需要了解的问题</p>
              </div>
              <div className='pt-20 f-24' style={{ color: '#EA5413' }}>customer@jacking.cn</div>
            </div>
            <div className='text-left' style={{ width: 360 }}>
              <div className='pb-20' style={{ borderBottom: '1px solid #999' }}>
                <p className='f-24'>您还可以</p>
                <p className='f-14 pt-10'>莅临我们公司 直接与我们洽谈业务</p>
              </div>
              <div className='pt-20 pb-50 f-22' style={{ color: '#EA5413' }}>厦门市思明区新景中心B栋15F</div>
              <div style={{ borderBottom: '1px solid #999',height: 82}}></div>
              <div className='pt-20 f-14 c-666'>版权所有： 厦门崛鼎信息科技有限公司</div>
              <div className='f-14 c-666 beian'>备案号： <a href="https://beian.miit.gov.cn/" target="_blank" className="c-666">闽ICP备14012338号-6</a></div>
            </div>
            <div className='text-center flex-end' style={{ width: 260 }}>
              <div className='flex-wrap' style={{ width: 120 }}>
                <div className='bg-fff' style={{ width: 120, height: 120 }}>
                  <img src={require('../../assets/images/wxgzh.jpg')} style={{ width: 120, height: 120 }} alt=""/>
                </div>
                <p className='f-14  pt-10 pb-30' style={{ width: 120 }}>微信公众号</p>
                <div className='bg-fff' style={{ width: 120, height: 120 }}>
                  <img src={require('../../assets/images/jdcode.jpg')} style={{ width: 120, height: 120, }} alt=""/>
                </div>
                <p className='f-14 pt-10'  style={{ width: 120 }}>移动端网站</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  //组件生命周期
  componentWillMount() {
    //组件将要挂载  第二个触发
  }

  componentDidMount() {
    //组件挂载完成  第四个触发
    //dom操作和请求数据
    Storage.sessionStorage.set('href',window.location.href)
  }

  //数据更新生命周期
  shouldComponentUpdate(nextPorps, nextState) {
    //更新前触发，是否更新？返回true或false
    //console.log('是否更新数据');
    return true;
  }

  componentWillUpdate() {
    //组件更新前
    //console.log('更新数据前');
  }

  componentDidUpdate() {
    //组件更新后
    //console.log('更新数据后');
  }

  //组件销毁生命周期
  componentWillUnmount() {
    //组件销毁前
    console.log('组件销毁');
  }

  //在父组件改变props的值，触发函数
  componentWillReceiveProps() {

  }
}

export default footer;