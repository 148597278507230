import React from 'react';
import { HashRouter as Router } from "react-router-dom";
import 'babel-polyfill';
import './assets/css/App.css';
import './componcss/css.css';
import ScrollToTop from './config/ScrollToTop';
import Menu from './components/pc/menu';
import MenuH5 from './components/mobile/menu';
import Media from 'react-media'

function App() {
  return (
    <div>
      <Media query="(min-width: 1224px)">
        <Router>
          <ScrollToTop>
            <div className="App">
              <Menu />
            </div>
          </ScrollToTop>
        </Router>
      </Media>
      <Media query="(max-width: 1223px)">
        <Router>
          <ScrollToTop>
            <div className="App">
              <MenuH5 />
            </div>
          </ScrollToTop>
        </Router>
      </Media>
    </div>
  );
}

export default App;
