
//菜单路由
import HomeIndex from '../components/pc/index';
import AboutUs from '../components/pc/aboutUs';
import Product from '../components/pc/product';
import Solution from '../components/pc/solution';
import JoinUs from '../components/pc/joinUs';
//子路由
import solutionDetail from '../components/pc/solutionDetail';

let Router = [
  {//菜单路由
    path: "/",
    exact: true,
    component: HomeIndex,
  },{//菜单路由
    path: "/AboutUs",
    exact: false,
    component: AboutUs,
  },{//菜单路由
    path: "/Product",
    exact: false,
    component: Product,
  },{//菜单路由
    path: "/Solution",
    exact: false,
    component: Solution,
  },{//菜单路由
    path: "/JoinUs",
    exact: false,
    component: JoinUs,
  },{//子路由
    path: "/solutionDetail",
    exact: false,
    component: solutionDetail,
  }
];

export default Router;