import React, {Component} from 'react';
import Storage from "../../config/Storage";
import {Link} from "react-router-dom";

class solution extends Component {
  //构造函数  第一个触发
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      dataIndex: 0,
      //银行金融： 0，电商平台： 1，AI应用： 2，其他：3
      data: [{
        image: require('../../assets/images/image_case_yunqifu.jpg'),
        title: '云企付',
        describe: '企业支付渠道解决方案',
        dataIndex: 0,
        detail: [require('../../assets/images/yqf_01.jpg'),require('../../assets/images/yqf_02.jpg'),require('../../assets/images/yqf_03.jpg')]
      }, {
        image: require('../../assets/images/image_case_qizhiyun.jpg'),
        title: '企助云',
        describe: 'O2O新零售合伙人商城平台',
        dataIndex: 1,
        detail: [require('../../assets/images/qzy_01.jpg'),require('../../assets/images/qzy_02.jpg'),require('../../assets/images/qzy_03.jpg'),require('../../assets/images/qzy_04.jpg'),require('../../assets/images/qzy_05.jpg')]
      }, {
        image: require('../../assets/images/image_case_bank.jpg'),
        title: '银行金融产品',
        describe: '银行金融业务系统解决方案',
        dataIndex: 0,
        detail: [require('../../assets/images/kb_01.jpg'),require('../../assets/images/kb_02.jpg'),require('../../assets/images/kb_03.jpg'),require('../../assets/images/kb_04.jpg'),
          require('../../assets/images/kb_05.jpg'),require('../../assets/images/kb_06.jpg'),require('../../assets/images/kb_07.jpg'),require('../../assets/images/kb_08.jpg')]
      }, {
        image: require('../../assets/images/image_case_xiaoyuanbao.jpg'),
        title: '校园宝',
        describe: 'AI智慧校园云账单系统',
        dataIndex: 2,
        detail: [require('../../assets/images/xyb_01.jpg'),require('../../assets/images/xyb_02.jpg'),require('../../assets/images/xyb_03.jpg'),require('../../assets/images/xyb_04.jpg'),require('../../assets/images/xyb_05.jpg'),require('../../assets/images/xyb_06.jpg'),require('../../assets/images/xyb_07.jpg')]
      }, {
        image: require('../../assets/images/image_case_pinjia.jpg'),
        title: '企助云订货',
        describe: 'B2B供应链商城平台',
        dataIndex: 1,
        detail: [require('../../assets/images/b2b_01.jpg'),require('../../assets/images/b2b_02.jpg'),require('../../assets/images/b2b_03.jpg'),require('../../assets/images/b2b_04.jpg')]
      }, {
        image: require('../../assets/images/image_case_lanren.jpg'),
        title: '懒人阅读',
        describe: '软件开发解决方案',
        dataIndex: 3,
        detail: [require('../../assets/images/lryd_01.jpg'),require('../../assets/images/lryd_02.jpg'),require('../../assets/images/lryd_03.jpg'),require('../../assets/images/lryd_04.jpg')]
      }, {
        image: require('../../assets/images/image_case_kaoqin.jpg'),
        title: '智慧考勤',
        describe: 'AI智慧考勤系统解决方案',
        dataIndex: 2,
        detail: [require('../../assets/images/wkq_01.jpg'),require('../../assets/images/wkq_02.jpg'),require('../../assets/images/wkq_03.jpg'),require('../../assets/images/wkq_04.jpg')]
      }, {
        image: require('../../assets/images/image_case_canteen.jpg'),
        title: '智慧食堂',
        describe: 'AI智慧食堂管理系统',
        dataIndex: 2,
        detail: [require('../../assets/images/zhst_01.jpg'),require('../../assets/images/zhst_02.jpg'),require('../../assets/images/zhst_03.jpg'),require('../../assets/images/zhst_04.jpg'),require('../../assets/images/zhst_05.jpg'),require('../../assets/images/zhst_06.jpg'),require('../../assets/images/zhst_07.jpg')]
      }, {
        image: require('../../assets/images/image_case_rpa.jpg'),
        title: 'RPA自动化流程系统',
        describe: '解决企业流程自动化难题',
        dataIndex: 2,
        detail: [require('../../assets/images/rpa_01.jpg'),require('../../assets/images/rpa_02.png'),require('../../assets/images/rpa_03.png'),require('../../assets/images/rpa_04.png')]
      }, {
        image: require('../../assets/images/image_case_ocr.jpg'),
        title: 'OCR票据识别系统',
        describe: '票据影像智能识别处理系统',
        dataIndex: 2,
        detail: [require('../../assets/images/ocr_01.jpg'),require('../../assets/images/ocr_02.jpg')]
      }, {
        image: require('../../assets/images/ERP.png'),
        title: 'ERP Cloud',
        describe: '企助云智能ERP解决方案',
        dataIndex: 2,
        detail: [require('../../assets/images/erp_01.jpg'),require('../../assets/images/erp_02.jpg'),require('../../assets/images/erp_03.jpg'),require('../../assets/images/erp_04.jpg')]
      }]
    }
  }

  getData = (index) => {
    let that = this;
    that.state.list = [];
    that.state.list = that.state.data.filter(item => item.dataIndex === index);
    that.setState({
      list: that.state.list,
      dataIndex: index
    })
  };

  linkDetail = (data) => {
    if(data.detail.length){
      Storage.sessionStorage.set('detail',data)
    }else {
      alert("内容更新中，敬请期待！")
    }
  };

  //数据渲染
  render() {
    return (
      <div className='solution'>
        <div className='mar-auto bg-fff w0100'>
          <div className='c-888 f-28 space-around pt-25 w0100 mar-auto pos-fixed bor-t bor-b-1px-e5' style={{ top: '1rem' }}>
            <div className='cursor' onClick={this.getData.bind(this,0)}>
              <span className={this.state.dataIndex === 0 ? 'pb-25 c-ea5413 bor-b-2-EA5413' : 'pb-pb-5 c-888'}>银行金融</span>
            </div>
            <div className='cursor' onClick={this.getData.bind(this,1)}>
              <span className={this.state.dataIndex === 1 ? 'pb-25 c-ea5413 bor-b-2-EA5413' : 'pb-5 c-888'}>电商平台</span>
            </div>
            <div className='cursor' onClick={this.getData.bind(this,2)}>
              <span className={this.state.dataIndex === 2 ? 'pb-25 c-ea5413 bor-b-2-EA5413' : 'pb-5 c-888'}>AI应用</span>
            </div>
            <div className='cursor' onClick={this.getData.bind(this,3)}>
              <span className={this.state.dataIndex === 3 ? 'pb-25 c-ea5413 bor-b-2-EA5413' : 'pb-5 c-888'}>其他</span>
            </div>
          </div>
          <ul className='space-around flex-wrap SUL' style={{ paddingTop: '2rem' }}>
            {
              this.state.list.map((val,key) => {
                if(val.detail.length){
                  return ( <Link to='/solutionDetail'  key={key}><li className='mart-20' onClick={this.linkDetail.bind(this,val)} style={{ width: '3.44rem' }}>
                    <div style={{ width: '3.44rem',height: '2.54rem',overflow: 'hidden' }}><img src={ val.image } style={{ width: '3.44rem' }} alt=""/></div>
                    <div className='bg-fff text-center' style={{ width: '3.44rem' }}>
                      <p className='c-333 f-28 pt-20'>{ val.title }</p>
                      <p className='c-666 f-24 pt-10 pb-30'>{ val.describe }</p>
                    </div>
                  </li></Link> )
                }else {
                  return (<li key={key} className='mart-20' onClick={this.linkDetail.bind(this,val)} style={{ width: '3.44rem' }}>
                    <div style={{ width: '3.44rem',height: '2.54rem',overflow: 'hidden' }}><img src={ val.image } style={{ width: '3.44rem' }} alt=""/></div>
                    <div className='bg-fff text-center' style={{ width: '3.44rem' }}>
                      <p className='c-333 f-28 pt-20'>{ val.title }</p>
                      <p className='c-666 f-24 pt-10 pb-30'>{ val.describe }</p>
                    </div>
                  </li>)
                }
              })
            }
            <li style={{ width: '3.44rem' }}> </li>
          </ul>
        </div>
      </div>
    )
  }

  //组件生命周期
  componentWillMount() {
    //组件将要挂载  第二个触发
  }

  componentDidMount() {
    //组件挂载完成  第四个触发
    //dom操作和请求数据
    this.getData(this.state.dataIndex)
  }

  //数据更新生命周期
  shouldComponentUpdate(nextPorps, nextState) {
    //更新前触发，是否更新？返回true或false
    //console.log('是否更新数据');
    return true;
  }

  componentWillUpdate() {
    //组件更新前
    //console.log('更新数据前');
  }

  componentDidUpdate() {
    //组件更新后
    //console.log('更新数据后');
  }

  //组件销毁生命周期
  componentWillUnmount() {
    //组件销毁前
    console.log('组件销毁');
  }

  //在父组件改变props的值，触发函数
  componentWillReceiveProps() {

  }
}

export default solution;