import React, {Component} from 'react';
import Storage from '../../config/Storage';
import Footer from './footer';

class solutionDetail extends Component {
  //构造函数  第一个触发
  constructor(props) {
    super(props);
    this.state = {
      detail: {
        detail: []
      }
    }
  }

  getDetail = () => {
    let detail = Storage.sessionStorage.get('detail');
    if(!detail){
      window.history.go(-1)
    }else {
      this.setState({ detail })
    }
  };

  //数据渲染
  render() {
    return (<>
        <div style={{ height: 80, background: "rgb(28,28,28)" }}></div>
        <div className='solutionDetail bg-fff'>
          <div className='bor-b-1px pb-30 pt-30 mar-auto' style={{ width: 1200 }}>
            <p className='c-333 f-28'>{ this.state.detail.title }</p>
            <p className='c-666 f-14 pt-10'>{ this.state.detail.describe }</p>
          </div>
          <ul className='flex pt-30 flex-wrap mar-auto'  style={{ width: 1200,paddingBottom: 30 }}>
            {
              this.state.detail.detail.map((val,key) => {
                return (
                  <li key={key}>
                    <img src={val} alt=""/>
                  </li>
                )
              })
            }
          </ul>
          <Footer />
        </div>
      </>
    )
  }

  //组件生命周期
  componentWillMount() {
    //组件将要挂载  第二个触发
  }

  componentDidMount() {
    //组件挂载完成  第四个触发
    //dom操作和请求数据
    this.getDetail()
  }

  //数据更新生命周期
  shouldComponentUpdate(nextPorps, nextState) {
    //更新前触发，是否更新？返回true或false
    //console.log('是否更新数据');
    return true;
  }

  componentWillUpdate() {
    //组件更新前
    //console.log('更新数据前');
  }

  componentDidUpdate() {
    //组件更新后
    //console.log('更新数据后');
  }

  //组件销毁生命周期
  componentWillUnmount() {
    //组件销毁前
    Storage.sessionStorage.remove('detail');
    console.log('组件销毁');
  }

  //在父组件改变props的值，触发函数
  componentWillReceiveProps() {

  }
}

export default solutionDetail;