import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Footer from './footer';

class index extends Component {
  //构造函数  第一个触发
  constructor(props) {
    super(props);
    this.state = {
      list: [{
        url: require('../../assets/images/image_banner1.jpg')
      }, {
        url: require('../../assets/images/image_banner2.jpg')
      }, {
        url: require('../../assets/images/image_banner3.jpg')
      }],
      toMoveIndex: 0,
      slideWidth: 0,
      slideLeft: 0,
      slide: true,
      Interval: '',
      developmentList: [{
        icon: require('../../assets/images/ic_home_bank.png'),
        title: '银行金融解决方案',
        instructions: '满足金融行业对交易时间准确、系统数据超高安全存储的要求，帮助金融客户快速实现网络部署，提升企业竞争力。',
        index: 0
      },{
        icon: require('../../assets/images/ic_home_mall.png'),
        title: '电商平台解决方案',
        instructions: '全渠道电商解决方案，助力企业构建私域流量进入品牌电商新时代。完整开店系统，轻松搭建在线商城，分销裂变提高销量，让人人都是商家分销员。',
        index: 1
      },{
        icon: require('../../assets/images/ic_home_ai.png'),
        title: 'AI应用解决方案',
        instructions: '精耕细作，持续创新，用人工智能产品取代手工输入，精准提供文字识别服务，为用户呈现结构化数据，提升工作效率。',
        index: 2
      }],
      customerList: [{
        url: require('../../assets/images/image_logo_spd.jpg')
      }, {
        url: require('../../assets/images/image_logo_gree.jpg')
      }, {
        url: require('../../assets/images/image_logo_stategrid.jpg')
      }, {
        url: require('../../assets/images/image_logo_lianzhong.jpg')
      }/*, {
        url: require('../assets/images/image_logo_pinjia.jpg')
      },{
        url: require('../assets/images/image_logo_winpay.jpg')
      }, {
        url: require('../assets/images/image_logo_xiaoyuanbao.jpg')
      }, {
        url: require('../assets/images/image_logo_yunqifu.jpg')
      }*/],
      mouseHover1: false,
      mouseHover2: false,
      mouseHover3: false,
      mouseHover4: false,
    }
  }

  AutoSlide = () => {
    let that = this;
    let index = this.state.toMoveIndex;
    switch (index) {
      case 0:
        that.state.slideLeft = 0;
        that.setState({
          slideLeft: that.state.slideLeft
        });
        break;
      case 1:
        that.state.slideLeft = -100;
        that.setState({
          slideLeft: that.state.slideLeft
        });
        break;
      default:
        that.state.slideLeft = -200;
        that.setState({
          slideLeft: that.state.slideLeft
        });
        break;
    }
  };

  //上一张图片
  icon_back = () => {
    let index = this.state.toMoveIndex;
    if (index !== 0) {
      index--;
      this.setState({
        toMoveIndex: index
      })
    } else {
      index = 2;
      this.setState({
        toMoveIndex: index
      })
    }
    this.AutoSlide();
  };
  //下一张图片
  icon_next = () => {
    let index = this.state.toMoveIndex;
    if (index !== 2) {
      index++;
      this.setState({
        toMoveIndex: index
      })
    } else {
      index = 0;
      this.setState({
        toMoveIndex: index
      })
    }
    this.AutoSlide();
  };

  //自动轮播
  AutoMatical = () => {
    let index = this.state.toMoveIndex;
    if (index < 2) {
      index = index + 1;
    } else {
      index = 0
    }
    this.setState({
      toMoveIndex: index
    });
    this.AutoSlide()
  };

  //鼠标移到按钮，清除定时器
  clear = () => {
    let that = this;
    clearInterval(that.state.Interval)
  };
  //鼠标离开按钮，挂起定时器
  hangUp = () => {
    let that = this;
    that.state.Interval = setInterval(that.AutoMatical, 3000);
    that.setState({
      Interval: that.state.Interval
    })
  };

  //鼠标移入
  onHover = (e) => {
    let key = 'mouseHover' + e;
    this.setState({
      [key]: true
    })
  };
  //鼠标离开
  onLeave = (e) => {
    let key = 'mouseHover' + e;
    this.setState({
      [key]: false
    })
  };
  //数据渲染
  render() {
    const {slide, slideWidth, slideLeft} = this.state;
    return (
            <div className="Index">

              <div className='tomove'>
                <ul className={slide ? 'tomove-list transition flex' : 'tomove-list flex'}
                    style={{width: slideWidth + '%', left: slideLeft + '%'}}>
                  {
                    this.state.list.map((val, key) => {
                      return (<li className={ slideWidth/2 + "%" } key={key}>
                        <img className='w0100' src={val.url} alt=""/>
                      </li>)
                    })
                  }
                </ul>
                <div className='slippage'>
                  {
                    this.state.list.map((val, key) => {
                      return (<div style={{width: 20, height: 2, margin: '0 5px'}} className={this.state.toMoveIndex === key ? 'bg-fff' : 'bg-666'} key={key}></div>)
                    })
                  }
                </div>
                <div className='icon icon_back'>
                  <button onClick={this.icon_back} onMouseEnter={this.clear} onMouseLeave={this.hangUp}>
                    <img style={{width: 28}} src={require('../../assets/images/ic_back.png')} alt=""/></button>
                </div>
                <div className='icon icon_next'>
                  <button onClick={this.icon_next} onMouseEnter={this.clear} onMouseLeave={this.hangUp}>
                    <img style={{width: 28}} src={require('../../assets/images/ic_next.png')} alt=""/></button>
                </div>
                <div className='f-44 c-fff title'>崛起科技 · 鼎创未来</div>
                <div className='f-16 c-fff dec'>
                  <p className='hl-36'>依托云计算数据中心，以行业内领先的大数据技术及产品体系为基础，运用成熟的运营模式为企业 提供定制化的大数据产品和服务</p>
                </div>
              </div>
              <div className='bg-fff' style={{ paddingBottom: 70 }}>
                <div className='text-center mar-auto pos-relative' style={{ width: 1200,height: 170 }}>
                  <p className='f-28 c-333 w0100 bigTitle'>了解我们的产品</p>
                  <p className='f-14 c-999 w0100 subTitle'>不断完善产品和服务，助力企业升级发展</p>
                </div>
                <ul className='space-between mar-auto' style={{ width: 1200 }}>
                  {
                    this.state.developmentList.map((val,key) => {
                      return(<Link to={"/Solution?index=" + val.index}  key={key}><li className='develop text-center'>
                        <img src={ val.icon } alt="" />
                        <p className='f-18 c-333 p1'>{ val.title }</p>
                        <p className='f-14 c-999 p2 text-left'>{ val.instructions }</p>
                      </li></Link>)
                    })
                  }
                </ul>
              </div>
              <div>
                <img src={ require("../../assets/images/image_home_map.png") } alt=""/>
              </div>
              <div className='bg-fff' style={{ paddingBottom: 70 }}>
                <div className='text-center mar-auto pos-relative' style={{ width: 1200,height: 170 }}>
                  <p className='f-28 c-333 w0100 bigTitle'>服务和支持</p>
                  <p className='f-14 c-999 w0100 subTitle'>您给我们信任，我们给您惊喜</p>
                  <p className='bg-fff f-14 c-666'></p>
                </div>
                <div className='mar-auto flex' style={{ width: 1200 }}>
                  <div className='flex-wrap' style={{ width: 460 }}>
                    <div className='text-center mar-b-20' style={{ width: 460,height: 190,backgroundColor: '#DD6B4D' }}>
                      <p className='f-18 c-fff pt-40'>我们的服务</p>
                      <p className='f-14 c-fff pt-25 pl-30 pr-30'>厦门崛鼎信息科技有限公司，经过多年的经验积累及沉淀，开发出一系列标准软件产品，均采用“标准+定制”的模式，可以为您提供相关的各类软件应用</p>
                    </div>
                    {
                      this.state.mouseHover1?(
                          <div style={{ backgroundColor: '#1C4B82',height:190,lineHeight: '190px',width: 460 }} onMouseEnter={this.onHover.bind(this,1)} onMouseLeave={this.onLeave.bind(this,1)}>
                            <p className='f-18 c-fff'>承接软件开发、平台定制、办公OA等定制服务</p>
                          </div>
                      ):(<div className='flex c-fff'  onMouseEnter={this.onHover.bind(this,1)} onMouseLeave={this.onLeave.bind(this,1)}>
                        <img style={{ height: 190,display: 'block' }} src={require('../../assets/images/image_yellow.jpg')} alt=""/>
                        <div className='f-18' style={{ width: 180, backgroundColor: '#1C4B82',height:190,lineHeight: '190px'}}>定制开发</div>
                      </div>)
                    }
                  </div>
                  <div style={{ width: 280,margin: '0 20px' }}>
                    {
                      this.state.mouseHover2?(
                              <div style={{ backgroundColor: '#183661',height:400,lineHeight: '400px',width: 280 }} onMouseEnter={this.onHover.bind(this,2)} onMouseLeave={this.onLeave.bind(this,2)}>
                                <p className='f-18 c-fff'>一体化平台产品</p>
                              </div>
                      ):(<div className='flex c-fff'  onMouseEnter={this.onHover.bind(this,2)} onMouseLeave={this.onLeave.bind(this,2)}>
                        <img style={{ height: 400 }} src={require('../../assets/images/image_purple.jpg')} alt=""/>
                      </div>)
                    }
                  </div>
                  <div className='flex-wrap' style={{ width: 420 }}>
                    {
                      this.state.mouseHover3?(
                              <div style={{ backgroundColor: '#16BDD8',height:240,lineHeight: '240px',width: 420 }} onMouseEnter={this.onHover.bind(this,3)} onMouseLeave={this.onLeave.bind(this,3)}>
                                <p className='f-18 c-fff'>致力于商业、企业及个人软件开发解决方案</p>
                              </div>
                      ):(<div className='flex text-center' style={{ width: 420,height: 240,backgroundColor: '#16BDD8' }} onMouseEnter={this.onHover.bind(this,3)} onMouseLeave={this.onLeave.bind(this,3)}>
                        <img style={{ height: 240 }} src={require('../../assets/images/image_green.jpg')} alt=""/>
                        <div className='f-18 c-fff' style={{ width: 200, backgroundColor: '#16BDD8',height:240,lineHeight: '240px'}}>软件产品</div>
                    </div>)
                    }
                    {
                      this.state.mouseHover4?(
                              <div className='mart-20' style={{ backgroundColor: '#8CA1B2',height:140,lineHeight: '140px',width: 420 }} onMouseEnter={this.onHover.bind(this,4)} onMouseLeave={this.onLeave.bind(this,4)}>
                                <p className='f-18 c-fff'>办公OA系统</p>
                              </div>
                      ):(<div className='mart-20'  onMouseEnter={this.onHover.bind(this,4)} onMouseLeave={this.onLeave.bind(this,4)}>
                        <img style={{ height: 140 }} src={require('../../assets/images/image_blue.jpg')} alt=""/>
                      </div>)
                    }
                  </div>
                </div>
              </div>
              <div className='bg-f8f8f8' style={{ paddingBottom: 180 }}>
                <div className='text-center mar-auto pos-relative' style={{ width: 1200,height: 170 }}>
                  <p className='f-28 c-333 w0100 bigTitle'>关于我们</p>
                  <p className='f-14 c-999 w0100 subTitle'>用科技创造价值，为企业助力</p>
                  <p className='bg-fff f-14 c-666'></p>
                </div>
                <div className='mar-auto space-between text-left' style={{ width: 1200 }}>
                  <div className='pt-40' style={{ width: 690 }}>
                    <p className='c-333 f-18 pt-10'>厦门崛鼎信息科技有限公司</p>
                    <p className='c-888 f-14 pt-30'>成立于2013年，是一家专业从事互联网云计算技术开发与运营服务为一体的高新科技企业。以“互联网+”为理念，利用人工智能、云计算、大数据、物联网等与现代制造业结合，为企业、银行及政府提供互联网应用开发、快捷支付、人工智能等综合解决方案。</p>
                    <Link to='./aboutUs'><button className='about'>了解更多</button></Link>
                  </div>
                  <div className='pt-30'>
                    <img style={{ width: 400 }} src={require('../../assets/images/image_company.jpg')} alt=""/>
                  </div>
                </div>
              </div>
              <div className='pos-relative pt-100 bg-fff' style={{ paddingBottom: 80 }}>
                <div style={{ position: 'absolute',width: '100%',top: -100}}>
                  <div className='posCut space-between mar-auto'>
                    <div className='w03333 text-center'>
                      <p className='f-54 c-666'>39 <sup className='f-15 c-888'>+</sup></p>
                      <p className='f-14 c-888'>成功案例</p>
                    </div>
                    <div className='w03333 text-center bor-l-1px'>
                      <p className='f-54 c-666'>6 <sup className='f-15 c-888'>年</sup></p>
                      <p className='f-14 c-888'>互联网服务</p>
                    </div>
                    <div className='w03333 text-center bor-l-1px'>
                      <p className='f-54 c-666'>16 <sup className='f-15 c-888'>个</sup></p>
                      <p className='f-14 c-888'>服务客户</p>
                    </div>
                  </div>
                </div>
                <div className='text-center mar-auto pos-relative pt-20' style={{ width: 1200,height: 90 }}>
                  <p className='f-28 c-333 w0100 bigTitle'>合作伙伴</p>
                  <p className='f-14 c-999 w0100 subTitle'>不断完善产品和服务，助力企业不断发展</p>
                  <p className='bg-fff f-14 c-666'> </p>
                </div>
                <ul className='space-between mar-auto flex-wrap' style={{ width: 1200 }}>
                  {
                    this.state.customerList.map((val,key)=>{
                      return ( <li key={key} className='pt-60'><img style={{ width: 279 }} src={val.url} alt=""/></li> )
                    })
                  }
                </ul>
              </div>
              <Footer />
            </div>
    )
  }

  //组件生命周期
  componentWillMount() {
    //组件将要挂载  第二个触发

  }

  componentDidMount() {
    //组件挂载完成  第四个触发
    //dom操作和请求数据
    let that = this;
    that.state.slideWidth = that.state.list.length * 100;
    that.setState({
      slideWidth: that.state.slideWidth
    });
    that.state.Interval = setInterval(that.AutoMatical, 3000);
    that.setState({
      Interval: that.state.Interval
    })
  }

  //数据更新生命周期
  shouldComponentUpdate(nextPorps, nextState) {
    //更新前触发，是否更新？返回true或false
    //console.log('是否更新数据');
    return true;
  }

  componentWillUpdate() {
    //组件更新前
    //console.log('更新数据前');
  }

  componentDidUpdate() {
    //组件更新后
    //console.log('更新数据后');
  }

  //组件销毁生命周期
  componentWillUnmount() {
    //组件销毁前
    this.clear();
    console.log('组件销毁');
  }

  //在父组件改变props的值，触发函数
  componentWillReceiveProps() {

  }
}

export default index;