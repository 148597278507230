import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import RouterComponent from '../../router/RouterComponent';
import Storage from '../../config/Storage';

class menu extends Component {
  //构造函数  第一个触发
  constructor(props) {
    super(props);
    this.state = {
      index: 0,
      sticky: false,
      menuList: [{
        name: '首页',
        url: '/',
        index: 0
      },{
        name: '产品与解决方案',
        url: '/Product',
        index: 1
      },{
        name: '关于我们',
        url: '/AboutUs',
        index: 2
      },{
        name: '加入我们',
        url: '/JoinUs',
        index: 3
      }]
    }
  }


  getIndex = () => {
    let that = this,href = Storage.sessionStorage.get('href');
    if(href.indexOf('/solutionDetail')>-1 || href.indexOf('/Solution')>-1){
      this.setState({
        index: 1
      })
    }else {
      that.state.menuList.forEach(item=>{
        if(href.indexOf(item.url)>-1){
          this.setState({
            index: item.index
          })
        }
      })
    }
  };

  //数据渲染
  render() {
    const { sticky } = this.state;
    return (
      <div className='menu'>
        <div>
          <div className='pos-fixed top-head hl-80 z-index-99' id="top-head" style={{ backgroundColor: sticky ? "#FFF":"rgba(0,0,0,0)", boxShadow: sticky ? "0px 4px 10px rgba(2,31,65,0.15)":"" }}>
            <div className='page-header space-between'>
              <div className='flex'>
                {
                  sticky ? <img style={{ width: 172, height: 40, margin: "20px 0" }} src={ require('../../assets/images/image_web_logo.png') } alt="" />:
                          <img style={{ width: 172, height: 40, margin: "20px 0" }} src={ require('../../assets/images/image_web_logo_white.png') } alt="" />
                }
              </div>
              <ul className='flex menuList'>
                {
                  this.state.menuList.map((value,key) => {
                    return (<Link to={value.url} key={key}>
                               <li className={sticky ? "c-333":"c-fff"}><span className={value.index!==this.state.index?"":sticky?"bor-t-3px-ea5413":"bor-t-3px"}>{value.name}</span></li>
                             </Link>)
                  })
                }
            </ul>
            </div>
          </div>
        </div>
        <RouterComponent />
      </div>
    )
  }

  //组件生命周期
  componentWillMount() {
    //组件将要挂载  第二个触发
  }

  componentDidMount() {
    //组件挂载完成  第四个触发
    //dom操作和请求数据
    let that = this;
    setInterval(()=>{
      that.getIndex();
    },500);
    window.onscroll = function () {
      //为了保证兼容性，这里取两个值，哪个有值取哪一个
      //scrollTop就是触发滚轮事件时滚轮的高度
      var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      if(scrollTop > 80){
        that.setState({
          sticky: true
        })
      }else {
        that.setState({
          sticky: false
        })
      }
    };
  }

  //数据更新生命周期
  shouldComponentUpdate(nextPorps, nextState) {
    //更新前触发，是否更新？返回true或false
    //console.log('是否更新数据');
    return true;
  }

  componentWillUpdate() {
    //组件更新前
    //console.log('更新数据前');
  }

  componentDidUpdate() {
    //组件更新后
    //console.log('更新数据后');

  }

  //组件销毁生命周期
  componentWillUnmount() {
    //组件销毁前
    console.log('组件销毁');
  }

  //在父组件改变props的值，触发函数
  componentWillReceiveProps() {

  }
}

export default menu;