import React, {Component} from 'react';
import { Map,Marker } from 'react-amap';
import Footer from './footer';

class aboutUs extends Component {
  //构造函数  第一个触发
  constructor(props) {
    super(props);
    this.state = {
      banner: require('../../assets/images/image_about_banner.jpg'),
      list: [{
        icon: require("../../assets/images/ic_develop_flow.png"),
        title: "完善的开发流程",
        desc: "采用业内较严谨的开发流程，较大限度的保证开发效率"
      },{
        icon: require("../../assets/images/ic_develop_team.png"),
        title: "专业的团队支持",
        desc: "我们拥有超过10年的互联网从业经历视野高、执行力强、快速响应"
      },{
        icon: require("../../assets/images/ic_develop_cord.png"),
        title: "自主知识产权",
        desc: "我们的产品均为自主开发，同时保证系统的安全性、稳定性"
      },{
        icon: require("../../assets/images/ic_develop_ai.png"),
        title: "AI人工智能",
        desc: "全球领先的AI人工智能开发服务科技让复杂的世界更简单"
      }],
      rqList: [{
        url: require('../../assets/images/rz0.jpg')
      },{
        url: require('../../assets/images/rz1.jpg')
      },{
        url: require('../../assets/images/rz2.jpg')
      },{
        url: require('../../assets/images/rz3.jpg')
      },{
        url: require('../../assets/images/rz4.jpg')
      }],
      mapPlugins: ['ToolBar'],
      mapCenter:  {longitude: 118.12070, latitude: 24.48028},
      markerPosition: {longitude: 118.12070, latitude: 24.48028},
      label: { content: '厦门市思明区新景中心B栋15F' },
      contactUsList: [{
        icon: require('../../assets/images/ic_contact_addr.png'),
        title: '地址',
        describe: '厦门市思明区新景中心B栋15F'
      }, {
        icon: require('../../assets/images/ic_contact_tel.png'),
        title: '电话',
        describe: '17338788103 / 0592-5193773'
      }, {
        icon: require('../../assets/images/ic_contact_email.png'),
        title: '邮件',
        describe: 'customer@jacking.cn'
      }],
    }
  }

  //数据渲染
  render() {
    return (
      <div className='AboutUs'>
        <div className='w0100'>
          <img src={this.state.banner} className='w0100' alt=""/>
        </div>
        <div className='bg-fff' style={{ paddingBottom: 70 }}>
          <div className='text-center mar-auto pos-relative' style={{ width: 1200,height: 150 }}>
            <p className='f-28 c-333 w0100 bigTitle'>公司简介</p>
            <p className='f-14 c-999 w0100 subTitle'>About Us</p>
            <p className='bg-fff f-14 c-666'> </p>
          </div>
          <div className='mar-auto text-left' style={{ width: 1200 }}>
            <p className='f-14 c-666' style={{ lineHeight: '30px' }}>厦门崛鼎信息科技有限公司（简称：崛鼎科技）成立于2013年，是一家专业从事互联网云计算技术开发与运营服务为一体的高新科技企业。以“互联网+”为理念，利用人工智能、云计算、大数
              据、物联网等与现代制造业结合，为企业、银行及政府提供互联网应用开发、快捷支付、人工智能等综合解决方案。</p>
{/*
            <p className='f-14 c-666 pt-40' style={{ lineHeight: '30px' }}>未来，崛鼎将打造一个完整的互联网营销闭环，为企业提供互联网营销工具、广告分发渠道、用户引导转化工具等全方位互联网营销解决方案，并整合优质企业服务商引进崛鼎商业营销联盟，实 现企业与企业服务商、企业服务商与网络营销推广商之间的无缝对接，联合构建成开放式的企业互联网商业生态圈。</p>
*/}
            <p className='f-14 c-666 pt-40' style={{ lineHeight: '30px' }}>无论企业是否具有互联网运营经验，崛鼎都将承载他们，助力他们在新商业浪潮中乘风破浪驶向更宽广的未来。</p>
          </div>
          <div className='mar-auto text-left' style={{ width: 1200 }}>
            <p className='pt-40 c-333 f-16 f-bold'>一流的团队</p>
            <p className='pt-20 c-666 f-14'>创始团队在微软、阿里等一线互联网公司从业多年，视野高、执行力强、快速响应。</p>
          </div>
          <div className='mar-auto text-left' style={{ width: 1200 }}>
            <p className='pt-40 c-333 f-16 f-bold'>专业的技术</p>
            <p className='pt-20 c-666 f-14'>支持过亿PV/UV的互联网架构设计、超大型系统的设计实现及Owner经验、可运维的平台级技术架构、一流的视觉及用户体验设计。</p>
          </div>
        </div>
        <div>
          <img style={{ width: "100%" }} src={ require("../../assets/images/image_about_target.png") } alt=""/>
        </div>
        <div className='bg-fff' style={{ paddingBottom: 70 }}>
          <div className='text-center mar-auto pos-relative' style={{ width: 1200,height: 150 }}>
            <p className='f-28 c-333 w0100 bigTitle'>我们的优势</p>
            <p className='f-14 c-999 w0100 subTitle'>Our Advantages</p>
            <p className='bg-fff f-14 c-666'> </p>
          </div>
          <ul className="w-1200 mar-auto space-between" >
            {
              this.state.list.map((val,key) => {
                return (<li key={key} className="advantage">
                  <img src={val.icon} alt=""/>
                  <p className="f-18 c-333">{val.title}</p>
                  <p className="c-666 f-14">{val.desc}</p>
                </li>)
              })
            }
          </ul>
        </div>
        <div className='bg-f8f8f8' style={{ paddingBottom: 70 }}>
          <div className='text-center mar-auto pos-relative' style={{ width: 1200,height: 150 }}>
            <p className='f-28 c-333 w0100 bigTitle'>荣誉及专利</p>
            <p className='f-14 c-999 w0100 subTitle'>Honors & Patents</p>
            <p className='bg-fff f-14 c-666'> </p>
          </div>
          <ul className="mar-auto space-between" style={{ width: 584 }}>
            <li className="text-center bg-fff" style={{ width: 262 }}>
              <img src={ require("../../assets/images/image_about_gaoxin.png") } alt=""/>
              <p className="f-18 hl-60 c-333">高新技术企业证书</p>
            </li>
            <li className="text-center bg-fff" style={{ width: 262 }}>
              <img src={ require("../../assets/images/image_about_software.png") } alt=""/>
              <p className="f-18 hl-60 c-333">软件企业证书</p>
            </li>
          </ul>
        </div>
        <div className='bg-fff' style={{ paddingBottom: 70 }}>
          <div className='text-center mar-auto pos-relative' style={{ width: 1200,height: 150 }}>
            <p className='f-28 c-333 w0100 bigTitle'>荣誉及专利</p>
            <p className='f-14 c-999 w0100 subTitle'>Honors & Patents</p>
            <p className='bg-fff f-14 c-666'> </p>
          </div>
          <ul className="w-1200 mar-auto space-between">
            {
              this.state.rqList.map((val,key) => {
                return (<li key={key}>
                  <img src={val.url} alt=""/>
                </li>)
              })
            }
          </ul>
        </div>
        <div className='bg-f8f8f8'  style={{ paddingBottom: 70 }}>
          <div className='text-center mar-auto pos-relative' style={{ width: 1200,height: 150 }}>
            <p className='f-28 c-333 w0100 bigTitle'>联系地址</p>
            <p className='f-14 c-999 w0100 subTitle'>Contact Us</p>
            <p className='bg-fff f-14 c-666'> </p>
          </div>
          <div className='mar-auto' style={{width: 1200,height:"320px"}}>
            <Map
                    plugins={this.state.mapPlugins}
                    center={this.state.mapCenter}
                    zoom={16}
                    keyboardEnable={false}
            >
              <Marker position={this.state.markerPosition} />
            </Map>
          </div>
        </div>
        <Footer />
      </div>
    )
  }

  //组件生命周期
  componentWillMount() {
    //组件将要挂载  第二个触发
  }

  componentDidMount() {
    //组件挂载完成  第四个触发
    //dom操作和请求数据
  }

  //数据更新生命周期
  shouldComponentUpdate(nextPorps, nextState) {
    //更新前触发，是否更新？返回true或false
    //console.log('是否更新数据');
    return true;
  }

  componentWillUpdate() {
    //组件更新前
    //console.log('更新数据前');
  }

  componentDidUpdate() {
    //组件更新后
    //console.log('更新数据后');
  }

  //组件销毁生命周期
  componentWillUnmount() {
    //组件销毁前
    console.log('组件销毁');
  }

  //在父组件改变props的值，触发函数
  componentWillReceiveProps() {

  }
}

export default aboutUs;