import React, {Component} from 'react';
import {Link} from "react-router-dom";
import RouterComponent from '../../router/RouterComponent1';
class menu extends Component {
  //构造函数  第一个触发
  constructor(props) {
    super(props);
    this.state = {
      menuIcon: require('../../assets/images/bt_home_menu.png'),
      isShow: false,
      index: 0,
      menuList: [{
        name: '首页',
        url: '/',
        index: 0
      },{
        name: '产品与解决方案',
        url: '/Solution',
        index: 1
      },{
        name: '关于我们',
        url: '/AboutUs',
        index: 2
      },{
        name: '加入我们',
        url: '/JoinUs',
        index: 3
      }]
    }
  }

  //菜单隐藏显示
  onMenuShow = () => {
    this.setState({
      isShow: !this.state.isShow
    })
  };

  //数据渲染
  render() {
    return (
      <div className='menuH5'>
        <div className='pos-fixed bg-fff w0100 hl-100 space-between'>
          <div className='pl-10 flex'>
            <img onClick={this.onMenuShow} style={{ width: '0.48rem',height: '0.48rem',padding: '0.26rem' }} src={ this.state.menuIcon } alt=""/>
          </div>
          <div className='flex'>
            <img style={{ width: '2.24rem',height: '0.48rem',paddingTop: '0.26rem' }} src={ require('../../assets/images/image_web_logo.svg') } alt="" />
          </div>
          <div className='hl-100' style={{ width: '1rem' }}> </div>
        </div>
        {
          this.state.isShow?<div className='pos-fixed menuList f-30'>
          <div className='hl-100 bor-b-1px'>
            <div className='flex-end'>
              <img onClick={this.onMenuShow} style={{ width: '0.36rem',height: '0.36rem',padding: '0.32rem'  }} src={require('../../assets/images/close.png')} alt=""/>
            </div>
          </div>
          <ul>
              {
                this.state.menuList.map((value,key) => {
                  return (<Link to={value.url} key={key}>
                    <li className='pl-50 hl-100 text-left c-fff bor-b-1px' onClick={this.onMenuShow}>{value.name}</li>
                  </Link>)
                })
              }
          </ul>
        </div>:''}
        <RouterComponent />
      </div>
    )
  }

  //组件生命周期
  componentWillMount() {
    //组件将要挂载  第二个触发

  }

  componentDidMount() {
    //组件挂载完成  第四个触发
    //dom操作和请求数据

  }

  //数据更新生命周期
  shouldComponentUpdate(nextPorps, nextState) {
    //更新前触发，是否更新？返回true或false
    //console.log('是否更新数据');
    return true;
  }

  componentWillUpdate() {
    //组件更新前
    //console.log('更新数据前');
  }

  componentDidUpdate() {
    //组件更新后
    //console.log('更新数据后');
  }

  //组件销毁生命周期
  componentWillUnmount() {
    //组件销毁前
    console.log('组件销毁');
  }

  //在父组件改变props的值，触发函数
  componentWillReceiveProps() {

  }
}

export default menu;