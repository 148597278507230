const storage={
  localStorage: {
    set: function(key,value){
      localStorage.setItem(key,JSON.stringify(value));
    },
    get: function(key){
      return JSON.parse(localStorage.getItem(key));
    },
    remove: function(key){
      localStorage.removeItem(key)
    },
    clear: function () {
      localStorage.clear()
    }
  },
  sessionStorage: {
    set: function(key,value){
      sessionStorage.setItem(key,JSON.stringify(value));
    },
    get: function(key){
      return JSON.parse(sessionStorage.getItem(key));
    },
    remove: function(key){
      sessionStorage.removeItem(key)
    },
    clear: function () {
      sessionStorage.clear()
    }
  }
};

export default storage;