import React, {Component} from 'react';

class NotFound extends Component {
  //构造函数  第一个触发
  constructor(props) {
    super(props);
    this.state = {}
  }

  //数据渲染
  render() {
    return (
      <div style={{lineHeight: '200px',textAlign:'center'}}>
        sorry，the page was not found!
      </div>
    )
  }

  //组件生命周期
  componentWillMount() {
    //组件将要挂载  第二个触发
  }

  componentDidMount() {
    //组件挂载完成  第四个触发
    //dom操作和请求数据
  }

  //数据更新生命周期
  shouldComponentUpdate(nextPorps, nextState) {
    //更新前触发，是否更新？返回true或false
    //console.log('是否更新数据');
    return true;
  }

  componentWillUpdate() {
    //组件更新前
    //console.log('更新数据前');
  }

  componentDidUpdate() {
    //组件更新后
    //console.log('更新数据后');
  }

  //组件销毁生命周期
  componentWillUnmount() {
    //组件销毁前
    console.log('组件销毁');
  }

  //在父组件改变props的值，触发函数
  componentWillReceiveProps() {

  }
}

export default NotFound;