import React, {Component} from 'react';
import Footer from './footer';

class joinUs extends Component {
  //构造函数  第一个触发
  constructor(props) {
    super(props);
    this.state = {
      banner: require('../../assets/images/image_join_banner.jpg'),
      list: [{
        position: "项目经理",
        demandList: ["1、 计算机及其相关专业，大专及以上学历；",
          "2、 深入了解Java EE，深入了解SSH应用框架；",
          "3、 精通JSP、Java Script、JDBC、Servlet、XML、CSS、AJAX、JQuery等编程；",
          "4、 熟练运用Struts、Spring、Hibernate等技术；",
          "5、 精通SQL语言，熟悉Oracle、MySQL数据库产品；",
          "6、 熟悉软件开发流程、设计模式和体系结构；",
          "7、良好的软件设计意识，熟悉UML，良好的编码习惯；",
          "8、熟悉主流的开源项目、中间件产品；",
          "9、能承受工作压力，有带领团队完整开发项目经验者优先；",
          "10、良好的团队协作精神，良好的沟通能力和书面报告能力。"]
      },{
        position: ".NET开发工程师",
        demandList: ["1、全日制本科以上学历，软件工程、计算机相关专业。",
        "2、具有1年以上工作经验，经验丰富者优先；",
        "3、精通.NET、C#、SqlServer；",
        "4、熟悉MVC、EF、Dapper、SignalR、Redis、Azure",
        "5、良好的学习能力和团队配合能力，工作积极热情。"]
      },{
        position: "Java开发工程师",
        demandList: ["1、计算机及其相关专业，全日制本科及以上学历；",
        "2、JAVA基础知识扎实，一年以上JAVA开发工作经验；",
        "3、熟练掌握Spring、hibernate、mybatis等开源框架，熟练WebService编程，MVC开发模式；",
        "4、熟悉mysql、oracle等关系型数据库；",
        "5、具有强烈的责任感，良好的沟通和表达能力，优秀的抗压能力；",
        "6、对技术有钻研精神。"]
      },{
        position: "软件测试工程师",
        demandList: ["1、计算机及其相关专业，全日制本科及以上学历；",
        "2、1年以上软件测试工作经验；",
        "3、熟悉白盒、黑盒测试方法，具有一定的软件测试代码编写能力；",
        "4、了解金融相关知识；有金融行业测试经验者优先；",
        "5、具有强烈的责任感，良好的沟通和表达能力，优秀的抗压能力；",
        "6、对技术有钻研精神。"]
      },{
        position: "互联网产品助理",
        demandList: ["1、本科及以上学历，2年以上工作经验，有移动互联网产品工作经验优先；",
        "2、具备独立撰写产品文档的能力，熟练掌握产品需求分析、设计，对交互设计过程有深入的了解，有成功的产品策划案例者优先；",
        "3、对工作充满热情，有创新精神，能承受较大的工作压力；",
        "4、熟练使用：Axure、Xmind等原型及流程工具。"]
      }]
    }
  }

  //数据渲染
  render() {
    return (
            <div className='ContactUs'>
              <div className='w0100'>
                <img src={this.state.banner} className='w0100' alt=""/>
              </div>
              <div className='bg-fff' style={{paddingBottom: 70}}>
                <div className='text-center mar-auto pos-relative' style={{width: 1200, height: 150}}>
                  <p className='f-28 c-333 w0100 bigTitle'>员工关怀与福利</p>
                  <p className='f-14 c-999 w0100 subTitle'>Care & Welfare</p>
                  <p className='bg-fff f-14 c-666'></p>
                </div>
                <div className="hl-38 text-center c-666 f-14" style={{height: 370}}>
                  <p>在这里，有年底双薪+五险一金+项目奖金+学习基金+股票期权等着你！</p>
                  <p>在这里，有专业培训、技术分享，工作氛围融洽！</p>
                  <p>在这里，有员工生日礼、年度旅游、王者荣耀争霸赛、宿舍厨艺PK、户外拓展、爬山、烧烤、漂流、划船等等活动帮大家舒展筋骨、放松大脑！</p>
                  <p>在这里，崇尚效率与自我管理，伙伴和boss都超级nice!</p>
                  <p>还有超多福利和各种好玩的等你一起加入！</p>
                  <p>Come on，一起学习创新，迎接挑战，未来互联网软件行业的领跑者就是你！</p>
                  <p>在这里，可以让你的梦想成真！</p>
                  <p>在这里，你可以找到一群志同道合的伙伴！</p>
                  <p>小伙伴们，还等什么，实现梦想的的大门正在为你打开，快快加入吧~~~~~</p>
                  <p>欢迎投递简历至 hr@Jacking.cn，或拨打0592-5193773详询。联系人：卓小姐</p>
                </div>
              </div>
              <div className="pos-relative">
                <div style={{width: "100%", position: "absolute", top: 0, left: 0}}>
                  <div className='text-center mar-auto w-1200' style={{height: 150, margin: "0 auto"}}>
                    <p className='f-28 c-333 w-1200 bigTitle'>岗位需求</p>
                    <p className='f-14 c-999 w-1200 subTitle'>Job Requirements</p>
                    <p className='bg-fff f-14 c-666'></p>
                  </div>
                </div>
                <img src={require("../../assets/images/image_join_bg.png")} alt=""/>
              </div>
              <div className="bg-fff">
                <ul className="w-1200 mar-auto masonry" style={{paddingBottom: 80}}>
                  {
                    this.state.list.map((val,key) => {
                      return (<li className="item text-left" key={key}
                                  style={{padding: "80px 0", borderBottom: "1px solid #ddd", lineHeight: "20px"}}>
                        <p className="f-20 c-333 f-bold pb-10" style={{lineHeight: "34px"}}>{ val.position }</p>
                        {
                          val.demandList.map((item,index) => {
                            return (<p key={index} className="pt-30 f-14 c-666">
                              {item}
                            </p>)
                          })
                        }
                      </li>)
                    })
                  }
                </ul>
              </div>
              <Footer/>
            </div>
    )
  }

  //组件生命周期
  componentWillMount() {
    //组件将要挂载  第二个触发

  }

  componentDidMount() {
    //组件挂载完成  第四个触发
    //dom操作和请求数据
  }

  //数据更新生命周期
  shouldComponentUpdate(nextPorps, nextState) {
    //更新前触发，是否更新？返回true或false
    //console.log('是否更新数据');
    return true;
  }

  componentWillUpdate() {
    //组件更新前
    //console.log('更新数据前');
  }

  componentDidUpdate() {
    //组件更新后
    //console.log('更新数据后');
  }

  //组件销毁生命周期
  componentWillUnmount() {
    //组件销毁前
    console.log('组件销毁');
  }

  //在父组件改变props的值，触发函数
  componentWillReceiveProps() {

  }
}

export default joinUs;