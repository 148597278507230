import React, {Component} from 'react';
import { Link } from "react-router-dom"
import Footer from './footer';

class product extends Component {
  //构造函数  第一个触发
  constructor(props) {
    super(props);
    this.state = {
      banner: require('../../assets/images/image_product_banner.jpg'),
      products: [{
        imgUrl: require('../../assets/images/image_product_pic1.jpg'),
        title: '银行金融解决方案',
        descList: ["全方位助力互联网金融行业", "提供定制化网络解决方案"],
        index: 0
      },{
        imgUrl: require('../../assets/images/image_product_pic2.jpg'),
        title: '电商平台解决方案',
        descList: ["全渠道小程序电商解决方案","助力企业构建私域流量进入品牌电商新时代"],
        index: 1
      },{
        imgUrl: require('../../assets/images/image_product_pic3.jpg'),
        title: 'AI应用解决方案',
        descList: ["精耕细作，持续创新，精准识别，","为用户呈现结构化数据，提升工作效率。"],
        index: 2
      }]
    }
  }

  //数据渲染
  render() {
    return (
      <div className='product'>
        <div className='w0100'>
          <img src={this.state.banner} className='w0100' alt=""/>
        </div>
        <div className="bg-f8f8f8" style={{ padding: "80px 0" }}>
          <ul className="w-1200 mar-auto">
            {
              this.state.products.map((val,key) => {
                if(key%2 === 0){
                  return (<li key={key} className="flex bg-fff text-left" style={{ height: 480 }}>
                    <div style={{ padding: "136px 100px", width: 400 }}>
                      <p className="f-32 c-333 pb-20">{val.title}</p>
                      {
                        val.descList.map((item,index) => {
                          return (<p key={index} className="c-666 f-18" style={{ lineHeight: 1.6 }}>{item}</p>)
                        })
                      }
                      <p className="hl-52"> </p>
                      <Link to={"/Solution?index=" + val.index}><button className="hl-38 bg-fff realize" style={{ width: 150 }}>了解更多</button></Link>
                    </div>
                    <img src={val.imgUrl} alt=""/>
                  </li>)
                }else {
                  return (<li key={key} className="flex bg-fff text-left" style={{ height: 480 }}>
                    <img src={val.imgUrl} alt=""/>
                    <div style={{ padding: "136px 100px", width: 400 }}>
                      <p className="f-32 c-333 pb-20">{val.title}</p>
                      {
                        val.descList.map((item,index) => {
                          return (<p key={index} className="c-666 f-18" style={{ lineHeight: 1.6 }}>{item}</p>)
                        })
                      }
                      <p className="hl-52"> </p>
                      <Link to={"/Solution?index=" + val.index}><button className="hl-38 bg-fff realize" style={{ width: 150 }}>了解更多</button></Link>
                    </div>
                  </li>)
                }
              })
            }
          </ul>
        </div>
        <Footer />
      </div>
    )
  }

  //组件生命周期
  componentWillMount() {
    //组件将要挂载  第二个触发
  }

  componentDidMount() {
    //组件挂载完成  第四个触发
    //dom操作和请求数据
  }

  //数据更新生命周期
  shouldComponentUpdate(nextPorps, nextState) {
    //更新前触发，是否更新？返回true或false
    //console.log('是否更新数据');
    return true;
  }

  componentWillUpdate() {
    //组件更新前
    //console.log('更新数据前');
  }

  componentDidUpdate() {
    //组件更新后
    //console.log('更新数据后');
  }

  //组件销毁生命周期
  componentWillUnmount() {
    //组件销毁前
  }

  //在父组件改变props的值，触发函数
  componentWillReceiveProps() {

  }
}

export default product;