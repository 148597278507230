
//菜单路由
import HomeIndex from '../components/mobile/index';
import AboutUs from '../components/mobile/aboutUs';
import Solution from '../components/mobile/solution';
import JoinUs from '../components/mobile/joinUs';
//子路由
import solutionDetail from '../components/mobile/solutionDetail';

let Router1 = [
  {//菜单路由
    path: "/",
    exact: true,
    component: HomeIndex,
  },{//菜单路由
    path: "/AboutUs",
    exact: false,
    component: AboutUs,
  },{//菜单路由
    path: "/Solution",
    exact: false,
    component: Solution,
  },{//菜单路由
    path: "/JoinUs",
    exact: false,
    component: JoinUs,
  },{//子路由
    path: "/solutionDetail",
    exact: false,
    component: solutionDetail,
  }
];

export default Router1;